<script setup lang="ts">
const authStore = useAuthStore()

useHead({
  title: null
})
</script>

<template>
  <LayoutBrochureware>
    <UCard>
      <template #header>
        <UButton v-if="!authStore.isLoggedIn" to="/auth/register" label="Register" color="black" />
        <UButton v-if="!authStore.isLoggedIn" to="/auth/login" label="Login" color="black" />
      </template>
    </UCard>
  </LayoutBrochureware>
</template>
